h4 {
  font-family: var(--body);
  color: var(--black);
  font-weight: var(--font-weight-semibold);
  margin: 0;
  transition: var(--transition);
}

.dark-mode h4 {
  color: var(--white);
}
