h1 {
  font-family: var(--header);
  color: var(--black);
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 60px; */
  margin: 2rem 0;
  text-align: center;
  text-transform: none;
  transition: var(--transition);
}

.dark-mode h1 {
  color: var(--white);
}

@media only screen and (max-width: 800px) {
  h1 {
    font-size: 36px;
  }
}
