.detail-container {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  padding: 2rem 1rem;
  max-width: 710px;
  margin: auto;
}

.detail-image {
  width: 100%;
  margin: 1rem 0 2rem 0;
}

.single-image {
  width: 50%;
}

.button-center {
  margin-top: 2rem;
  text-align: center;
}

.spacer-thing {
  margin-bottom: 2rem;
}
