
.navigation {
  width: 100%;
  position: sticky;
  top: 0;
  height: 5rem;
  display: flex;
  align-items: flex-end;
  justify-content: right;
  border-bottom: 5px solid var(--border-color-light);
  margin: auto;
  background-color: var(--white);
  transition: var(--transition);
  z-index: 1;
  padding-bottom: 0.45rem;
  display: none;

}

.dark-mode .navigation {
  background-color: var(--black);
  border-bottom: 5px solid var(--gray);
}

.desktop-nav-items {
  display: flex;
}

.nav-logo {
  position: absolute;
  left: 0;
  right: 0;
  /* top: 0; */
  bottom: 0;
  margin: 0;
  height: 3.5rem;
  padding-bottom: 0.45rem;
}

.nav-logo:hover {
  position: absolute;
  left: 0;
  right: 0;
  /* top: 0; */
  bottom: 0;
  margin: 0;
  height: 3.5rem;
  opacity: 0.7;
  cursor: pointer;
  padding-bottom: 0.45rem;
}

.darkmode-nav-logo {
  position: absolute;
  left: 0;
  right: 0;
  /* top: 0; */
  bottom: 0;
  margin: auto;
  height: 3.5rem;
}

.nav-logo-white {
  position: absolute;
  left: 0;
  right: 0;
  /* top: 0; */

  bottom: 0;
  margin: auto;
  height: 3.5rem;
}
.mobile-nav-logo {
  position: relative;
  margin: 0;
}

@media only screen and (max-width: 800px) {
  .navigation .desktop-nav-items {
    display: none;
  }
}