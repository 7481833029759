.button-link-secondary {
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--gray);
  box-sizing: border-box;
  color: var(--black);
  cursor: pointer;
  display: inline-flex;
  fill: var(--black);
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  letter-spacing: -.8px;
  line-height: 24px;
  min-width: 180px;
  outline: 0;
  padding: 0 18px;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border-radius: 8px;
}


.button-link-secondary:hover {
    color: var(--black);
    box-shadow: inset 400px 0 0 0 #C9E4FF;
    transition: ease-in;
    transition-duration: 900ms;
    border: 1px solid var(--gray);

}

.button-link-secondary:focus {
  border-radius: 50px;

}

.button-link-.button-link-secondary {
  opacity: 0.4;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.dark-mode .button-link-secondary {
  /* background-color: var(--gray); */
}
