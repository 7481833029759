/* PORTFOLIO */
button {
justify-content: center;
font-size: 14px;
font-family: var(--body);
padding-bottom: 1rem;
padding-top: 1rem;
width: 71%;
text-align: center;
border: 5px solid #f2f2f2;
border-radius: 5px;
cursor: pointer;
box-shadow: 5px;
background-color: white;
}


.vc-new {
  padding: 3rem 0 0 0;
  text-align: center;
}

.vc_new_img {

  width: 73.5%;
  border-radius: 8px;
}

.vc-text {
padding-left: 9rem;
}



.box-container {
  padding: 3rem 0;
}

.resume-thing {
  text-align: center;
  padding-left: 0.5rem;

}

.resume-thing:hover {
  opacity: 0.72;

}

.online-container {
  padding: 1rem 0;
  text-align: center !important;
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.nav-container {
  padding: 1rem 0;
  text-align: center !important;
}

.online-container:hover {
  padding: 1rem 0;
  text-align: center !important;
  opacity: 0.8;
  cursor: pointer;
}


@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 2px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.online-image {
  width: 100%;
  max-width: 20rem;
}

.meeting-image {
  width: 100%;
  max-width: 19rem;
}

.meeting-container {
  padding: 1rem 0;
  text-align: center !important;
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.meeting-container:hover {
  padding: 1rem 0;
  text-align: center !important;
  opacity: 0.9;
  cursor: pointer;
  transition: ease-in;
}
.box {
  flex-direction: column;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  border-bottom: 0px solid #f2f2f2;
  margin: 0 0 2rem 0;
  transition-timing-function: ease-out;
  align-items: center;
}

.box:hover {
  flex-direction: column;
  opacity: 0.86;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  transition-timing-function: ease-in;
  margin: 0 0 2rem 0;
  cursor: pointer;
  align-items: center;


}

.box:focus {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  cursor: pointer;
}

.box .box-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  width: 96%;
  justify-content: center;
  border-radius: 8px;
}

.box .box-content:hover {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  width: 96%;
  justify-content: center;
  border-radius: 24px;
  transition: 0.4s ease-in-out;

}


@media only screen and (max-width: 800px) {
  /* PORTFOLIO */
  .box {
    flex-direction: column;
    padding: 0 0 4rem 0;
    align-items: flex-start;
  }

  button {
    justify-content: center;
    font-size: 14px;
    font-family: var(--body);
    padding-bottom: 1rem;
    padding-top: 1rem;
    width: 100%;
    text-align: center;
    border: 5px solid #f2f2f2;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 5px;
    background-color: white;
    }

  .resume-thing {
    width: 100%;
    text-align: center;  
  }
    

  .box:hover {
    flex-direction: column;
    padding: 0 0 4rem 0;
    align-items: flex-start;
  }

  .box .box-content {
    flex-direction: column;
    width: 100%;
  }

  .box .box-content:hover {
  flex-direction: column;
  width: 100%;
  border-radius: 16px;

}
  .box-content-right {
    order: unset;
  }

  .box .box-image {
    width: 100%;
    padding-bottom: 0;
  }

  .vc-new {
    padding: 3rem 0;
    text-align: center;
    margin-left: 12px;
    justify-content: center;
  }
  
  .vc_new_img {
  
    width: 100%;
    border-radius: 8px;
  }

  .vc-text {
    padding: 0rem;
    }
  
}
