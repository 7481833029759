/* ABOUT */
.about-image {
  width: 75%;
  transform: none;
  -webkit-filter: none;
  filter: none;
  transition: ease-out;
  transition: .5;
  border-radius: 3rem;
  padding: 2.1rem;
}

.flex-container {
  display: flex;
  padding: 2rem 0;
  width: 100%;
}

.grid-wrap {
  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.flex-box {
  border: 0.25px solid rgb(230, 230, 230);
  flex: 1;
  padding: 1.5rem;
  padding-top: 3rem;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.05);
}

.flex-box:hover {
  border: 0.25px solid rgb(214, 214, 214);
  opacity: 0.95;
  flex: 1;
  padding: 1.5rem;
  padding-top: 3rem;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.1);
  transition: 0.05s;
  transition-timing-function: ease-in;
  cursor: pointer;
}

.rec-box {
  flex: unset;
}

.rec-box-top {
  display: flex;
  border-bottom: 1px solid lightgray;
  margin-bottom: 1rem;
  width: 100%;
  align-items: center;
  padding-bottom: 1rem;
}

.rec-titles {
  margin-left: 1rem;
}

.flex-image {
  width: 3rem;
  height: 3rem;
  background-color: lightgray;
  border-radius: 50%;
  box-sizing: border-box;
  /* margin-bottom: 1rem; */
}

@media only screen and (max-width: 800px) {
  /* ABOUT */
  .flex-container {
    flex-direction: column;
  }

  .flex-box {
    max-width: 375px;
    margin: 1rem auto;
    padding: 1.5rem;
  }

  .grid-wrap {
    display: block;
  }

  .about-image {
    width: 92%;
    transform: none;
    filter: none;
    transition: ease-out;
    transition: 0.5;
    border-radius: 3rem;
    padding: 2.1rem;
  }
  .flex-box:hover {
    max-width: 375px;
    margin: 1rem auto;
    padding: 1.5rem;
    border: 0.25px solid rgb(214, 214, 214);
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
