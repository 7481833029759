h2 {
  font-family: var(--header);
  color: var(--black);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  margin: 1rem 0;
  text-align: center;
  text-transform: none;
  transition: var(--transition);
}

.dark-mode h2 {
  color: var(--white);
}
