@import url(https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&family=Open+Sans:wght@400;600;700&display=swap);
/* FONT IMPORTS */

/* VARIABLES */
:root {
  /* COLORS */
  --border-color-light: #f2f2f2;
  --white: #ffffff;
  --black: #111111;
  --gray: #54595f;
  --lightgray: #999999;
  --accent: #000cb1;

  /* NUMBERS */
  --max-width: 1100px;
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-regular: 400;
  --circle-radius: 50%;
  --button-radius: 25px;
  --corder-radius: 2px;
  --transition: 200ms ease-in-out;

  /* FONTS */
  --header: 'Noto Serif', serif;
  --body: 'Open Sans', sans-serif;
  --body-font-size: 16px;
  --body-line-height: 24px;

  /* SHADOW(S) */
  --shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

body,
html {
  padding: 0;
  margin: 0;
  transition: 200ms ease-in-out;
  transition: var(--transition);
}


body.dark-mode {
  background-color: #111111;
  background-color: var(--black);
}

.masthead {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto;
  max-width: 670px;
}

.left-justify {
  text-align: left;
}

.footerthing {
  padding: 1rem;
  text-align: center;
  margin-top: 6rem;
  font-family: 'Open Sans', sans-serif;
  font-family: var(--body);
  font-weight: 500;
  font-size: 14px;
  color: #333333;
  border-top: 5px solid #f2f2f2;
  display: none;;
}

.footerthing:hover {
  color: #585858;
}

.SocialButtonsContainer {
  text-align: center !important;
  width: auto !important;
  margin-top: 0.4rem;
  margin-right: 15px;
}

.SocialButtonsContainer:hover {
  opacity: 0.9;
}
.iframe-responsive {
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 500px;
}

@media only screen and (max-width: 800px) {
  .masthead {
    margin-bottom: 0rem;
  }

  .iframe-responsive {
    height: auto;
  }
}

.app-container {
  max-width: var(--max-width);
  margin: auto;
  padding: 4rem 8rem 8rem;

}


@media only screen and (max-width: 500px) {
 
  .app-container {
    padding: 2rem;
  }


.dark-mode .app-container {
  color: (--white);
}
}
a {
  font-family: var(--body);
  font-size: 15px;
  display: auto;
  align-items: flex-end;
  padding-bottom: 1rem;
  margin-right: 1.5rem;
  text-decoration: none;
  font-weight: var(--font-weight-regular);
  color: var(--lightgray);
  transition: var(--transition);
}

a:hover {
  opacity: 0.75;
}

a.mobile-link {
  font-size: 24px;
  margin: 1rem 0 0 0;
}

a.nav-active {
  color: var(--black);
}

.dark-mode a.nav-active {
  color: var(--white);
}

.mobile-navigation {
  display: none;
  position: absolute;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  right: 1rem;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.mobile-nav-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  top: 0;
  left: 100vw;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: var(--white);
  transition: var(--transition);
  padding: 1rem 2rem 0 0;
  box-sizing: border-box;
  z-index: 1;
}

.dark-mode .mobile-nav-menu {
  background-color: var(--black);
}

.mobile-nav-top {
  width: 100%;
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-nav-closer {
  height: 2.75rem;
  width: 2.75rem;
  padding: 0.5rem;
  align-items: center;
  font-size: 1.5rem;
  border-radius: var(--circle-radius);
  border: none;
  background-color: var(--border-color-light);
  cursor: pointer;
}

.dark-mode .mobile-nav-closer {
  background-color: var(--gray);
}

.mobile-nav-menu-active {
  display: flex;
  left: 0;
  /* opacity: 1; */
}

@media only screen and (max-width: 800px) {
  .navigation .mobile-navigation {
    display: flex;
  }
}


.navigation {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 5rem;
  display: flex;
  align-items: flex-end;
  justify-content: right;
  border-bottom: 5px solid var(--border-color-light);
  margin: auto;
  background-color: var(--white);
  transition: var(--transition);
  z-index: 1;
  padding-bottom: 0.45rem;
  display: none;

}

.dark-mode .navigation {
  background-color: var(--black);
  border-bottom: 5px solid var(--gray);
}

.desktop-nav-items {
  display: flex;
}

.nav-logo {
  position: absolute;
  left: 0;
  right: 0;
  /* top: 0; */
  bottom: 0;
  margin: 0;
  height: 3.5rem;
  padding-bottom: 0.45rem;
}

.nav-logo:hover {
  position: absolute;
  left: 0;
  right: 0;
  /* top: 0; */
  bottom: 0;
  margin: 0;
  height: 3.5rem;
  opacity: 0.7;
  cursor: pointer;
  padding-bottom: 0.45rem;
}

.darkmode-nav-logo {
  position: absolute;
  left: 0;
  right: 0;
  /* top: 0; */
  bottom: 0;
  margin: auto;
  height: 3.5rem;
}

.nav-logo-white {
  position: absolute;
  left: 0;
  right: 0;
  /* top: 0; */

  bottom: 0;
  margin: auto;
  height: 3.5rem;
}
.mobile-nav-logo {
  position: relative;
  margin: 0;
}

@media only screen and (max-width: 800px) {
  .navigation .desktop-nav-items {
    display: none;
  }
}
.dark-mode-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7);
  border: none;
  height: 2rem;
  width: 10rem;
  padding: 0;
  margin: 0 1rem 1rem 0;
  border-radius: 5px;
  cursor: pointer;
  display: none;
}

.dark-mode-mobile {
  margin: 1rem 0;
}

.dark-mode-button.dark {
  /* background-color: var(--gray); */
}


@media only screen and (max-width: 800px) {
  .dark-mode-desktop {
    display: none;
  }
  .dark-mode-mobile {
    display: flex;
  }
}
.body-text {
  color: var(--gray);
  font-family: var(--body);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  margin: 0 0 1rem 0;
  text-transform: none;
  transition: var(--transition);
  line-height: 1.75rem;
}

.no-margin {
  margin: 0;
}

.dark-mode .body-text {
  color: var(--white);
}

h1::before {
  transform: scaleX(0);
  transform-origin: bottom right;
}

h1:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

h1::before {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: hsl(200 100% 90%);
  z-index: -1;
  transition: transform 0.3s ease;
}

h1 {
  position: relative;
  font-size: 5rem;
  cursor: pointer;
}

h2 {
  font-family: var(--header);
  color: var(--black);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  margin: 1rem 0;
  text-align: center;
  text-transform: none;
  transition: var(--transition);
}

.dark-mode h2 {
  color: var(--white);
}

h4 {
  font-family: var(--body);
  color: var(--black);
  font-weight: var(--font-weight-semibold);
  margin: 0;
  transition: var(--transition);
}

.dark-mode h4 {
  color: var(--white);
}

/* ABOUT */
.about-image {
  width: 75%;
  transform: none;
  -webkit-filter: none;
  filter: none;
  transition: ease-out;
  transition: .5;
  border-radius: 3rem;
  padding: 2.1rem;
}

.flex-container {
  display: flex;
  padding: 2rem 0;
  width: 100%;
}

.grid-wrap {
  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.flex-box {
  border: 0.25px solid rgb(230, 230, 230);
  flex: 1 1;
  padding: 1.5rem;
  padding-top: 3rem;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.05);
}

.flex-box:hover {
  border: 0.25px solid rgb(214, 214, 214);
  opacity: 0.95;
  flex: 1 1;
  padding: 1.5rem;
  padding-top: 3rem;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.1);
  transition: 0.05s;
  transition-timing-function: ease-in;
  cursor: pointer;
}

.rec-box {
  flex: unset;
}

.rec-box-top {
  display: flex;
  border-bottom: 1px solid lightgray;
  margin-bottom: 1rem;
  width: 100%;
  align-items: center;
  padding-bottom: 1rem;
}

.rec-titles {
  margin-left: 1rem;
}

.flex-image {
  width: 3rem;
  height: 3rem;
  background-color: lightgray;
  border-radius: 50%;
  box-sizing: border-box;
  /* margin-bottom: 1rem; */
}

@media only screen and (max-width: 800px) {
  /* ABOUT */
  .flex-container {
    flex-direction: column;
  }

  .flex-box {
    max-width: 375px;
    margin: 1rem auto;
    padding: 1.5rem;
  }

  .grid-wrap {
    display: block;
  }

  .about-image {
    width: 92%;
    transform: none;
    -webkit-filter: none;
            filter: none;
    transition: ease-out;
    transition: 0.5;
    border-radius: 3rem;
    padding: 2.1rem;
  }
  .flex-box:hover {
    max-width: 375px;
    margin: 1rem auto;
    padding: 1.5rem;
    border: 0.25px solid rgb(214, 214, 214);
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.button-link {
  background-color: var(--accent);
  border-radius: 25px;
  padding: 1rem 2.5rem;
  border: none;
  color: var(--white);
  cursor: pointer;
  font-family: var(--body);
  font-weight: 700;
  font-size: 16px;
  transition: 200ms;
  margin: 0;
  transition: var(--transition);
  box-shadow: var(--shadow);
}

.button-link:hover {
  opacity: 0.7; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.dark-mode .button-link {
  /* background-color: var(--gray); */
}

h1 {
  font-family: var(--header);
  color: var(--black);
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 60px; */
  margin: 2rem 0;
  text-align: center;
  text-transform: none;
  transition: var(--transition);
}

.dark-mode h1 {
  color: var(--white);
}

@media only screen and (max-width: 800px) {
  h1 {
    font-size: 36px;
  }
}

h3 {
  font-family: var(--body);
  color: var(--black);
  margin: 1rem 0 0.5rem 0;
  font-weight: var(--font-weight-semibold);
  transition: var(--transition);
  text-align: center;
}

.dark-mode h3 {
  color: var(--white);
}

/* PORTFOLIO */
button {
justify-content: center;
font-size: 14px;
font-family: var(--body);
padding-bottom: 1rem;
padding-top: 1rem;
width: 71%;
text-align: center;
border: 5px solid #f2f2f2;
border-radius: 5px;
cursor: pointer;
box-shadow: 5px;
background-color: white;
}


.vc-new {
  padding: 3rem 0 0 0;
  text-align: center;
}

.vc_new_img {

  width: 73.5%;
  border-radius: 8px;
}

.vc-text {
padding-left: 9rem;
}



.box-container {
  padding: 3rem 0;
}

.resume-thing {
  text-align: center;
  padding-left: 0.5rem;

}

.resume-thing:hover {
  opacity: 0.72;

}

.online-container {
  padding: 1rem 0;
  text-align: center !important;
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.nav-container {
  padding: 1rem 0;
  text-align: center !important;
}

.online-container:hover {
  padding: 1rem 0;
  text-align: center !important;
  opacity: 0.8;
  cursor: pointer;
}


@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 2px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.online-image {
  width: 100%;
  max-width: 20rem;
}

.meeting-image {
  width: 100%;
  max-width: 19rem;
}

.meeting-container {
  padding: 1rem 0;
  text-align: center !important;
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.meeting-container:hover {
  padding: 1rem 0;
  text-align: center !important;
  opacity: 0.9;
  cursor: pointer;
  transition: ease-in;
}
.box {
  flex-direction: column;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  border-bottom: 0px solid #f2f2f2;
  margin: 0 0 2rem 0;
  transition-timing-function: ease-out;
  align-items: center;
}

.box:hover {
  flex-direction: column;
  opacity: 0.86;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  transition-timing-function: ease-in;
  margin: 0 0 2rem 0;
  cursor: pointer;
  align-items: center;


}

.box:focus {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  cursor: pointer;
}

.box .box-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1;
  width: 96%;
  justify-content: center;
  border-radius: 8px;
}

.box .box-content:hover {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1;
  width: 96%;
  justify-content: center;
  border-radius: 24px;
  transition: 0.4s ease-in-out;

}


@media only screen and (max-width: 800px) {
  /* PORTFOLIO */
  .box {
    flex-direction: column;
    padding: 0 0 4rem 0;
    align-items: flex-start;
  }

  button {
    justify-content: center;
    font-size: 14px;
    font-family: var(--body);
    padding-bottom: 1rem;
    padding-top: 1rem;
    width: 100%;
    text-align: center;
    border: 5px solid #f2f2f2;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 5px;
    background-color: white;
    }

  .resume-thing {
    width: 100%;
    text-align: center;  
  }
    

  .box:hover {
    flex-direction: column;
    padding: 0 0 4rem 0;
    align-items: flex-start;
  }

  .box .box-content {
    flex-direction: column;
    width: 100%;
  }

  .box .box-content:hover {
  flex-direction: column;
  width: 100%;
  border-radius: 16px;

}
  .box-content-right {
    order: unset;
  }

  .box .box-image {
    width: 100%;
    padding-bottom: 0;
  }

  .vc-new {
    padding: 3rem 0;
    text-align: center;
    margin-left: 12px;
    justify-content: center;
  }
  
  .vc_new_img {
  
    width: 100%;
    border-radius: 8px;
  }

  .vc-text {
    padding: 0rem;
    }
  
}

.button-link-secondary {
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--gray);
  box-sizing: border-box;
  color: var(--black);
  cursor: pointer;
  display: inline-flex;
  fill: var(--black);
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  letter-spacing: -.8px;
  line-height: 24px;
  min-width: 180px;
  outline: 0;
  padding: 0 18px;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border-radius: 8px;
}


.button-link-secondary:hover {
    color: var(--black);
    box-shadow: inset 400px 0 0 0 #C9E4FF;
    transition: ease-in;
    transition-duration: 900ms;
    border: 1px solid var(--gray);

}

.button-link-secondary:focus {
  border-radius: 50px;

}

.button-link-.button-link-secondary {
  opacity: 0.4; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.dark-mode .button-link-secondary {
  /* background-color: var(--gray); */
}

.detail-container {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  padding: 2rem 1rem;
  max-width: 710px;
  margin: auto;
}

.detail-image {
  width: 100%;
  margin: 1rem 0 2rem 0;
}

.single-image {
  width: 50%;
}

.button-center {
  margin-top: 2rem;
  text-align: center;
}

.spacer-thing {
  margin-bottom: 2rem;
}

