.body-text {
  color: var(--gray);
  font-family: var(--body);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  margin: 0 0 1rem 0;
  text-transform: none;
  transition: var(--transition);
  line-height: 1.75rem;
}

.no-margin {
  margin: 0;
}

.dark-mode .body-text {
  color: var(--white);
}

h1::before {
  transform: scaleX(0);
  transform-origin: bottom right;
}

h1:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

h1::before {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: hsl(200 100% 90%);
  z-index: -1;
  transition: transform 0.3s ease;
}

h1 {
  position: relative;
  font-size: 5rem;
  cursor: pointer;
}
