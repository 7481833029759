h3 {
  font-family: var(--body);
  color: var(--black);
  margin: 1rem 0 0.5rem 0;
  font-weight: var(--font-weight-semibold);
  transition: var(--transition);
  text-align: center;
}

.dark-mode h3 {
  color: var(--white);
}
