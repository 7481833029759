.button-link {
  background-color: var(--accent);
  border-radius: 25px;
  padding: 1rem 2.5rem;
  border: none;
  color: var(--white);
  cursor: pointer;
  font-family: var(--body);
  font-weight: 700;
  font-size: 16px;
  transition: 200ms;
  margin: 0;
  transition: var(--transition);
  box-shadow: var(--shadow);
}

.button-link:hover {
  opacity: 0.7;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.dark-mode .button-link {
  /* background-color: var(--gray); */
}
