.mobile-navigation {
  display: none;
  position: absolute;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  right: 1rem;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.mobile-nav-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  top: 0;
  left: 100vw;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: var(--white);
  transition: var(--transition);
  padding: 1rem 2rem 0 0;
  box-sizing: border-box;
  z-index: 1;
}

.dark-mode .mobile-nav-menu {
  background-color: var(--black);
}

.mobile-nav-top {
  width: 100%;
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-nav-closer {
  height: 2.75rem;
  width: 2.75rem;
  padding: 0.5rem;
  align-items: center;
  font-size: 1.5rem;
  border-radius: var(--circle-radius);
  border: none;
  background-color: var(--border-color-light);
  cursor: pointer;
}

.dark-mode .mobile-nav-closer {
  background-color: var(--gray);
}

.mobile-nav-menu-active {
  display: flex;
  left: 0;
  /* opacity: 1; */
}

@media only screen and (max-width: 800px) {
  .navigation .mobile-navigation {
    display: flex;
  }
}
