a {
  font-family: var(--body);
  font-size: 15px;
  display: auto;
  align-items: flex-end;
  padding-bottom: 1rem;
  margin-right: 1.5rem;
  text-decoration: none;
  font-weight: var(--font-weight-regular);
  color: var(--lightgray);
  transition: var(--transition);
}

a:hover {
  opacity: 0.75;
}

a.mobile-link {
  font-size: 24px;
  margin: 1rem 0 0 0;
}

a.nav-active {
  color: var(--black);
}

.dark-mode a.nav-active {
  color: var(--white);
}
