.app-container {
  max-width: var(--max-width);
  margin: auto;
  padding: 4rem 8rem 8rem;

}


@media only screen and (max-width: 500px) {
 
  .app-container {
    padding: 2rem;
  }


.dark-mode .app-container {
  color: (--white);
}
}