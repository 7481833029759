/* FONT IMPORTS */
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&family=Open+Sans:wght@400;600;700&display=swap');

/* VARIABLES */
:root {
  /* COLORS */
  --border-color-light: #f2f2f2;
  --white: #ffffff;
  --black: #111111;
  --gray: #54595f;
  --lightgray: #999999;
  --accent: #000cb1;

  /* NUMBERS */
  --max-width: 1100px;
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-regular: 400;
  --circle-radius: 50%;
  --button-radius: 25px;
  --corder-radius: 2px;
  --transition: 200ms ease-in-out;

  /* FONTS */
  --header: 'Noto Serif', serif;
  --body: 'Open Sans', sans-serif;
  --body-font-size: 16px;
  --body-line-height: 24px;

  /* SHADOW(S) */
  --shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

body,
html {
  padding: 0;
  margin: 0;
  transition: var(--transition);
}


body.dark-mode {
  background-color: var(--black);
}

.masthead {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto;
  max-width: 670px;
}

.left-justify {
  text-align: left;
}

.footerthing {
  padding: 1rem;
  text-align: center;
  margin-top: 6rem;
  font-family: var(--body);
  font-weight: 500;
  font-size: 14px;
  color: #333333;
  border-top: 5px solid #f2f2f2;
  display: none;;
}

.footerthing:hover {
  color: #585858;
}

.SocialButtonsContainer {
  text-align: center !important;
  width: auto !important;
  margin-top: 0.4rem;
  margin-right: 15px;
}

.SocialButtonsContainer:hover {
  opacity: 0.9;
}
.iframe-responsive {
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 500px;
}

@media only screen and (max-width: 800px) {
  .masthead {
    margin-bottom: 0rem;
  }

  .iframe-responsive {
    height: auto;
  }
}
