.dark-mode-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7);
  border: none;
  height: 2rem;
  width: 10rem;
  padding: 0;
  margin: 0 1rem 1rem 0;
  border-radius: 5px;
  cursor: pointer;
  display: none;
}

.dark-mode-mobile {
  margin: 1rem 0;
}

.dark-mode-button.dark {
  /* background-color: var(--gray); */
}


@media only screen and (max-width: 800px) {
  .dark-mode-desktop {
    display: none;
  }
  .dark-mode-mobile {
    display: flex;
  }
}